import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const FanMarketing2 = ({ location }) => {
  const title =
    "【2022年版】Twitter 企業アカウント運用のコツと注意点（ファンマーケティング）"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="【2022年版】Twitter 企業アカウント運用のコツと注意点（ファンマーケティング）"
          pagedesc="企業アカウントでのTwitter運用についてお悩みはございませんか。フォロワーの増やし方、フォロワー離れの防止策、運用上の注意など事例付きでご紹介します。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2022-fan-marketing/2022-fan-marketing.jpg"
          pagepath="/blog/2022-fan-marketing"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は7分で読むことができます
            </div>
            <h1>
              【2022年版】Twitter
              企業アカウント運用のコツと注意点（ファンマーケティング）
            </h1>
            <div className="blog__sns-container">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
             {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2022年10月9日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="チェスをしている男性"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2022-fan-marketing/2022-fan-marketing.jpg"
                className="w-full"
              />
            </figure>
            <div>
              <p>
                Twitterを始めたけど「フォロワーが増えない」「SNS運用って何をすればいいの？」
                そんなお悩みはございませんか。
              </p>
              <p>
                今回の記事では、企業アカウントでのTwitter運用のコツ・注意点・成功事例とその特徴をご紹介します。
              </p>
              <p>
                すぐにでも実践できる内容ですので、ぜひ最後までご覧ください。
              </p>
            </div>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    企業アカウントでのTwitter運用のコツ　
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id2" className="toc__link">
                        1. ターゲットに関連する有益な情報を発信する
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id3" className="toc__link">
                        2. ツイートの見栄えを工夫する
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id4" className="toc__link">
                        3. ハッシュタグを活用する
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id5" className="toc__link">
                        4. ユーザーとコミュニケーションをとる
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id6" className="toc__link">
                        5. プレゼントキャンペーンを実施する
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id7" className="toc__link">
                        6. 【注意】ルールや炎上対策を考える
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id8" className="toc__link">
                    企業アカウントの成功事例と特徴　５選
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id9" className="toc__link">
                        1. セブンイレブン
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id10" className="toc__link">
                        2. 赤城乳業
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id11" className="toc__link">
                        3. PayPay
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id12" className="toc__link">
                        4. オレオクッキー
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id13" className="toc__link">
                        5. Pairs
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id14" className="toc__link">
                    まとめ
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id1">企業アカウントでのTwitter運用のコツ　</h2>
            </div>
            <div>
              <h3 id="id2">1. ターゲットに関連する有益な情報を発信する</h3>
              <p>Twitterには世界中の莫大な数のツイートが存在します。</p>
              <p>
                その中でユーザーやターゲットのフォローを獲得するには、需要のある情報を発信することが大切です。
              </p>
              <p>
                例えば、新商品の発売やセール情報、キャンペーン、生活に役立つ豆知識などです。
              </p>
              <p>
                また、有益な情報を定期的に発信することで、ターゲットの目に触れる機会が多くなりフォロワー離れ防止にも繋がります。
              </p>
            </div>
            <div>
              <h3 id="id3">2. ツイートの見栄えを工夫する</h3>
              <p>
                どれだけ有益な情報を発信しても、見てもらえなければ意味がありません。
              </p>
              <p>
                そこで、ユーザーの目に留まりやすいツイート方法を次にまとめました。
              </p>
              <ul className="blog__border-list">
                <li>
                  ツイート文は必要な情報を読みやすい文面で投稿する
                  <p>
                    ・目立つからといって無闇に絵文字や文章を多くすれば良い訳ではありません。
                  </p>
                  <p>
                    ・また、ツイート文字数は140文字までという制限があります。
                  </p>
                  <p>
                    ・伝えたい情報を簡潔にまとめ、余白や適度な絵文字を使うことで情報が伝わりやすくなります。
                  </p>
                </li>
                <li>
                  画像や動画、GIFバナー画像を一緒に投稿する
                  <p>
                    ・文字のみの投稿では、やはりインパクトに欠けてしまいます。
                  </p>
                  <p>
                    ・画像や動画、GIFといったバナー画像を添付することにより、画面上に表示されるツイート面積が大きくなるため、目に留まりやすくなります。
                  </p>
                  <p>
                    ・また、ツイート内容に沿った見栄えの良い画像を添付することも重要です。
                  </p>
                </li>
                <li>
                  キャラクターや有名人とコラボする
                  <p>
                    ・画像や動画、GIFを添付する際に商品やサービスだけでなく、キャラクターや有名人を起用することでより印象を残し、注目度を上げることができます。
                  </p>
                  <p>
                    ・知名度の高いキャラクターや有名人の方がやはり話題になりやすいです。しかし自社マスコットやキャラクターでもファンを獲得できる可能性があり、投稿し続けることで記憶に残りやすくなります。
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <h3 id="id4">3. ハッシュタグを活用する</h3>
              <p>
                ツイート内容に関連するキーワードや季節のイベントなどにハッシュタグをつけて投稿することで、拡散力が高まります。また、引用リツイートやクイズ形式のキャンペーンにてユーザーにハッシュタグ投稿をしていただく拡散方法もあります。
              </p>
              <p>
                この場合、オリジナルのワードや商品名をハッシュタグにされることをおすすめします。
              </p>
            </div>
            <div>
              <h3 id="id5">4. ユーザーとコミュニケーションをとる</h3>
              <p>
                フォロワーを獲得し、フォロワー離れを防止する方法としてユーザーとのコミュニケーションがあります。自社のツイートにコメントをいただいたり、ツイートやリツイートしていただけた際に反応を示すことが大切です。コミュニケーションとして次の内容を実施されてはいかがでしょうか。
              </p>
              <ul className="blog__border-list">
                <li>挨拶ツイートを投稿する</li>
                <li>自社ツイートのコメントに返信する</li>
                <li>
                  リプライや引用リツイートされたら、いいねやコメントをする
                </li>
                <li>
                  フォロワーの自社に関する投稿をリプライや引用リツイートする
                </li>
              </ul>
              <p>
                しかし、莫大な数のコメントやツイートをいただけた場合、全てに反応を返すのは大変です。
              </p>
              <p>
                フォロワー離れを防ぎ、新規フォロワー獲得も可能な方法があります。それが、ユーザーが参加して楽しめるプレゼントキャンペーンです。詳しくは次の項目をご覧ください。
              </p>
            </div>
            <div>
              <h3 id="id6">5. プレゼントキャンペーンを実施する</h3>
              <p>
                前述した通り、プレゼントキャンペーンを実施することで既存フォロワー離れを防ぎ、新規フォロワーを獲得するチャンスに繋がります。
              </p>
              <p>Twitterで開催可能なキャンペーンは様々あります。</p>
              <ul className="blog__border-list">
                <li>
                  <a
                    href="https://www.park-lot.com/case-study/twitter/instant-win-follow-and-retweet"
                    target="_blank"
                    rel="noreferrer"
                  >
                    インスタントウィンキャンペーン
                  </a>
                  <p>フォローとリツイートで応募できるキャンペーン</p>
                </li>
                <li>
                  <a
                    href="https://www.park-lot.com/case-study/twitter/instant-win-follow-and-retweet"
                    target="_blank"
                    rel="noreferrer"
                  >
                    インスタントウィンキャンペーン
                  </a>
                  <p>応募したその場で当落結果がわかる仕組みのキャンペーン</p>
                </li>
                <li>
                  <a
                    href="https://www.park-lot.com/product/store-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    QRコードキャンペーン
                  </a>
                  <p>QRコード読み込みで参加できるキャンペーン</p>
                </li>
                <li>
                  <a
                    href="https://scratch.park-lot.com/?_gl=1%2a173xdwp%2a_ga%2aNTExOTY5NzAwLjE2NTIwNzcwNjA.%2a_ga_LCFPLPR8R1%2aMTY2NDk1MzQwMi40NS4wLjE2NjQ5NTM0MDIuMC4wLjA."
                    target="_blank"
                    rel="noreferrer"
                  >
                    スクラッチキャンペーン
                  </a>
                  <p>
                    抽選結果確認としてスクラッチをけずることができるキャンペーン
                  </p>
                </li>
                <li>
                  <a
                    href="https://www.park-lot.com/product/coupon-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    クーポン配布キャンペーン
                  </a>
                  <p>参加者にクーポンを配布できるキャンペーン</p>
                </li>
                <li>
                  リプライキャンペーン
                  <p>対象ツイートにリプライ（返信）してもらうキャンペーン</p>
                </li>
                <li>
                  引用リツイートキャンペーン
                  <p>
                    意見や感想などを書き加えて引用リツイートしてもらうキャンペーン
                  </p>
                </li>
                <li>
                  ハッシュタグキャンペーン
                  <p>
                    オリジナルのハッシュタグをつけてツイートしてもらうキャンペーン
                  </p>
                </li>
                <li>
                  リツイートorいいねキャンペーン
                  <p>
                    「リツイート」または「いいね」の好きなほうに応募・投票できるキャンペーン
                  </p>
                </li>
                <li>
                  カンバセーションボタン
                  <p>
                    最大4つのハッシュタグから好きなハッシュタグを選び、ツイートしてもらうキャンペーン
                  </p>
                </li>
                <li>
                  コンテンツ自動DM配布キャンペーン
                  <p>資料などのコンテンツをDMにて配布できるキャンペーン</p>
                </li>
              </ul>
              <p>
                フォロー＆リツイートキャンペーンは最もメジャーなキャンペーンです。参加へのハードルが低いので、参加者が集まりやすいのが特徴です。
              </p>
              <p>
                また、インスタントウィン形式のキャンペーンを実施することで参加意欲を高めます。自社製品やサービスの特性、目的に合わせTwitterにてプレゼントキャンペーンを開催されてみてはいかがでしょうか。
              </p>
              <p>
                ※{" "}
                <a
                  href="https://www.park-lot.com/blog/instant-win-campaign"
                  target="_blank"
                  rel="noreferrer"
                >
                  インスタントウィンキャンペーンに関してはこちらの記事
                </a>
                をご覧ください。
              </p>
            </div>
            <div>
              <h3 id="id7">6. 【注意】ルールや炎上対策を考える</h3>
              <p>
                Twitterを運用するメリットは高い拡散性です。多くのユーザーの目に触れる可能性があるので、些細な発言が企業イメージに大きく影響します。
              </p>
              <p>次の項目は特に注意が必要です。</p>
              <ul className="blog__border-list">
                <li>誤解を招く発言をしない</li>
                <li>差別的な発言をしない</li>
                <li>触れる話題や内容が適切かどうか考える</li>
                <li>ツイート内容の間違いは早急に訂正する</li>
              </ul>
              <p>
                Twitter運用に関しては社内でルールを定めることをおすすめします。
              </p>
            </div>
            <div>
              <h2 id="id8">企業アカウントの成功事例と特徴　５選</h2>
              <p>
                Twitterを上手に活用されている企業アカウントとその特徴をご紹介します。
              </p>
            </div>
            <div>
              <h3 id="id9">1. セブンイレブン</h3>
              <img
                className="blog__article-image"
                placeholder="tracedSVG"
                alt="セブンイレブン公式アカウント"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2022-fan-marketing/2022-fan-marketing-2.png"
              />
              <a
                href="https://twitter.com/711SEJ"
                target="_blank"
                rel="noreferrer"
              >
                セブンイレブン公式アカウント
              </a>
              <ul className="blog__border-list">
                <li>定期的なキャンペーンや商品紹介</li>
                <li>セブンイレブンの取り組みも発信し、社会的活動を公開</li>
                <li>バナーは画像や動画、GIFなどで目に留まる楽しい演出</li>
              </ul>
            </div>
            <div>
              <h3 id="id10">2. 赤城乳業</h3>
              <img
                className="blog__article-image"
                placeholder="tracedSVG"
                alt="赤城乳業公式アカウント"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2022-fan-marketing/2022-fan-marketing-3.png"
              />
              <a
                href="https://twitter.com/akagi_cp"
                target="_blank"
                rel="noreferrer"
              >
                赤城乳業公式アカウント
              </a>
              <ul className="blog__border-list">
                <li>
                  ガリガリくんの他にアニメのキャラクターや有名人とのコラボ企画を発信
                </li>
                <li>新発売商品の紹介</li>
                <li>定期的にキャンペーンを開催</li>
                <li>バナー画像や挿入動画も商品を全面に打ち出した凝った作り</li>
              </ul>
            </div>
            <div>
              <h3 id="id11">3. PayPay</h3>
              <img
                className="blog__article-image"
                placeholder="tracedSVG"
                alt="PayPay公式アカウント"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2022-fan-marketing/2022-fan-marketing-4.png"
              />
              <a
                href="https://twitter.com/PayPayOfficial"
                target="_blank"
                rel="noreferrer"
              >
                PayPay公式アカウント
              </a>
              <ul className="blog__border-list">
                <li>
                  おはようツイートにカンバセーションボタンで思わず参加したくなる投稿
                </li>
                <li>
                  PayPay導入店舗情報、クーポンやポイント還元キャンペーンなど有益な情報発信
                </li>
                <li>関連ツイートをリツイート</li>
                <li>発信したい情報が分かりやすく記載されたバナー画像</li>
              </ul>
            </div>
            <div>
              <h3 id="id12">4. オレオクッキー</h3>
              <img
                className="blog__article-image"
                placeholder="tracedSVG"
                alt="オレオクッキー公式アカウント"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2022-fan-marketing/2022-fan-marketing-5.png"
              />
              <a
                href="https://twitter.com/Oreojpn"
                target="_blank"
                rel="noreferrer"
              >
                オレオクッキー公式アカウント
              </a>
              <ul className="blog__border-list">
                <li>
                  オレオクッキーに関するTwitterユーザーの投稿を積極的にリツイート
                </li>
                <li>
                  オレオクッキーアレンジレシピや季節のイベントに合わせたツイートを投稿
                </li>
                <li>
                  オレオクッキーのパッケージカラーであるブルーとホワイトを使用し、シンプルかつオレオを連想しやすいバナー画像で印象に残りやすい
                </li>
              </ul>
            </div>
            <div>
              <h3 id="id13">5. Pairs</h3>
              <img
                className="blog__article-image"
                placeholder="tracedSVG"
                alt="Pairs公式アカウント"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2022-fan-marketing/2022-fan-marketing-6.png"
              />
              <a
                href="https://twitter.com/pairs_official"
                target="_blank"
                rel="noreferrer"
              >
                Pairs公式アカウント
              </a>
              <ul className="blog__border-list">
                <li>マッチングのために役立つノウハウを投稿</li>
                <li>
                  バナー画像はイメージカラーを使用し、統一感と印象に残りやすい演出
                </li>
                <li>Pairsに関するツイートを引用リツイート</li>
                <li>
                  カンバセーションボタンでのアンケートやプレゼントキャンペーンでユーザーが参加できる楽しい企画を実施
                </li>
              </ul>
            </div>
            <div>
              <h2 id="id14">まとめ</h2>
              <p>
                ユーザー数の多さや拡散性の高さ、投稿の手軽さがTwitterの大きなメリットです。ユーザーのニーズに合った情報を適切な方法でツイートし、企業アカウントを上手に活用しましょう。
              </p>
              <p>
                Twitterプレゼントキャンペーンをお考えであれば、ぜひPARKLoTにご相談ください！
              </p>
              <p>PARKLoTでは今回ご紹介したキャンペーンに対応しております。</p>
              <div className="blog__border-highlight">
                <ul>
                  <li>
                    <a
                      href="/case-study/twitter/instant-win-follow-and-retweet"
                      target="_blank"
                      rel="noreferrer"
                    >
                      インスタントウィンキャンペーン
                    </a>
                  </li>
                  <li>
                    <a
                      href="/case-study/twitter/twitter-non-instant-win"
                      target="_blank"
                      rel="noreferrer"
                    >
                      フォロー＆リツイート
                    </a>
                  </li>
                  <li>
                    <a
                      href="/product/hashtag-campaign"
                      target="_blank"
                      rel="noreferrer"
                    >
                      UGC（ハッシュタグ）
                    </a>
                  </li>
                  <li>
                    <a
                      href="/product/coupon-campaign"
                      target="_blank"
                      rel="noreferrer"
                    >
                      クーポン配布
                    </a>
                  </li>
                  <li>
                    <a
                      href="/product/store-campaign"
                      target="_blank"
                      rel="noreferrer"
                    >
                      QRコードキャンペーン
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://scratch.park-lot.com/?_gl=1%2a173xdwp%2a_ga%2aNTExOTY5NzAwLjE2NTIwNzcwNjA.%2a_ga_LCFPLPR8R1%2aMTY2NDk1MzQwMi40NS4wLjE2NjQ5NTM0MDIuMC4wLjA."
                      target="_blank"
                      rel="noreferrer"
                    >
                      スクラッチキャンペーン
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="kakefuda" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/fan-marketing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    プロが教えるTwitterインスタントウィン・キャンペーンのやり方。売上アップに効果的なのは「ファンマーケティング」
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-sns-marketing-is-necessary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    なぜSNSマーケティングが必要なのか？Twitterキャンペーンの戦略について紹介
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/risks-of-campaign-implementation"
                    target="_blank"
                    rel="noreferrer"
                  >
                    プレゼントキャンペーンを社内で実施するリスクとその回避方法
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-parklot-is-inexpensive"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/2021-campaign-result-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンを徹底比較！4つの比較テストから導き出した、成功の秘訣とは？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/examples-of-utilization"
                    target="_blank"
                    rel="noreferrer"
                  >
                    あのブランドはどうやってファンを獲得した?PARKLoT活用事例4選を解説!
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}

export default FanMarketing2
